import Header from '@/features/Header';
import MainLayout from '@/shared/layouts/MainLayout';
import Landing from '@/widgets/Landing';
import Footer from '@/features/Footer';
import { 
  useMinerAccount,
} from "@/processes/MinerAccounts";
import { SEO } from '@/processes/Router';
import JsonLdSchema from "@/processes/Router/components/JsonLdSchema";
import { wrapper } from '@/global/store';
import { withUserDataFromCookie } from '@/processes/Auth';

export default function Home() {
  const { isAuth, isAccounting } = useMinerAccount();
  return (
    <>
      <SEO/>
      <Header
        withAddonsLeft={isAuth}
        withSubAccounts={isAccounting}
        withAddonsCenter={!isAuth}
      />
      <MainLayout>
        <Landing/>
      </MainLayout>
      <Footer/>
      <JsonLdSchema />
    </>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(withUserDataFromCookie);